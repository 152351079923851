@tailwind components;

.ProseMirror-focused {
  @apply outline-0;
}

.tiptap p.is-editor-empty:first-child::before {
  @apply pointer-events-none float-left h-0 text-secondary-on-light;
  content: attr(data-placeholder);
}

@layer components {
  .editor-container {
    @apply relative cursor-text overflow-hidden transition duration-150 ease-in-out;
    --editor-rows: 1;
    --editor-max-rows: none;
    &.text-xs,
    &.text-sm {
      @apply leading-6;
      --editor-line-height: 1.5rem;
    }
    &.text-lg {
      --editor-line-height: 1.75rem;
    }
    &.text-xl {
      --editor-line-height: 2rem;
    }
    .editor {
      @apply box-content overflow-auto p-2;
      min-height: calc(
        var(--editor-line-height, /*base*/ 1.5rem) * var(--editor-rows)
      );
      max-height: calc(
        var(--editor-line-height, /*base*/ 1.5rem) * var(--editor-max-rows)
      );
    }

    &.rich {
      @apply flex flex-col;
    }
  }

  .editor-container:has(.editor[contenteditable="false"]) {
    @apply cursor-default;
  }

  .editor-container:has(.editor[contenteditable="false"]):not(.rich) {
    @apply opacity-40;
  }

  .rich .editor {
    & > * {
      @apply rounded-md p-1;

      &:hover {
        @apply outline outline-1 outline-secondary-border-hover-light;
      }
    }

    &[contenteditable="false"] > * {
      &:hover {
        @apply outline-0;
      }
    }
  }

  .toolbar {
    @apply z-10 flex gap-1 bg-white px-4 py-2;

    .toolbar-item {
      @apply rounded-md text-dusk-on;

      &:has(> svg) {
        @apply text-2xl;
      }

      &[data-focus-visible] {
        @apply outline outline-2 outline-offset-1 outline-primary-border/30;
      }

      &[data-active] {
        @apply text-white;
      }

      &:not(:active):hover {
        @apply ring-1 ring-inset;
      }

      &[aria-disabled="true"] {
        @apply opacity-40;
      }
    }

    .separator {
      @apply h-5 self-center border-r border-secondary-border-light;
    }
  }
}
